<template>
    <div class="container contact">
        <div class="columns">
            <div class="column is-3 text-block">
                <h1 class="text-block--title">{{ $t(`signup.textBlock.title`) }}</h1>

                <p class="text-block--text" v-html="$t(`signup.textBlock.text`)"></p>
            </div>
            <div class="column is-5 is-offset-2" v-if="formSuccess">
                <h2>{{ $t(`signup.form.success`) }}</h2>
            </div>
            <div class="column is-5 is-offset-2" v-if="!formSuccess">
                <h2 class="form-title">{{ $t(`signup.form.title`) }}</h2>
                <form class="form" @submit.prevent="submitForm">
                    <input type="text" :placeholder="$t(`signup.form.name`)" class="form-field" required v-model="form.name" name="name" :aria-label="$t(`signup.form.name`)">
                    <input type="text" :placeholder="$t(`signup.form.email`)" class="form-field" required v-model="form.email" name="email" :aria-label="$t(`signup.form.email`)">
                    <input type="text" :placeholder="$t(`signup.form.phone`)" class="form-field" required v-model="form.phone" name="phone" :aria-label="$t(`signup.form.phone`)">

                    <div class="columns">
                        <div class="column is-12 input-group">
                            <h4 class="input-group--title">{{ $t(`signup.form.course`) }}</h4>
                            <label class="radio-container">
                                <input type="radio" name="course" value="basic" v-model="form.course" v-on:change="emptyDates()">
                                <span class="radio"></span>
                                {{ $t(`signup.basic`) }}
                            </label>
                            <label class="radio-container">
                                <input type="radio" name="course" value="advanced" v-model="form.course" v-on:change="emptyDates()">
                                <span class="radio"></span>
                                {{ $t(`signup.advanced`) }}
                            </label>
                        </div>
                    </div>

                    <h4 class="input-group--title">{{ $t(`signup.form.available`) }}</h4>
                    <div class="columns" v-if="form.course.length > 0">
                        <div class="column is-12 input-group dates" v-if="form.course === 'basic' && availableDatesBasic.length > 0">
                            <label v-for="(date, index) in availableDatesBasic" :key="index" class="check-container">
                                <input type="checkbox" :value="date" v-model="form.checkedDates" name="available-dates">
                                <span class="checkmark"></span>
                                {{ dateFormat(date.start) }} <span class="till">{{ $t(`signup.till`) }}</span> {{ dateFormat(date.end) }}
                            </label>
                            <p>
                                {{ $t(`signup.form.nodate1`) }} <router-link :to="{path: '/contact'}" class="inline-link">contact</router-link> {{ $t(`signup.form.nodate2`) }}
                            </p>
                        </div>
                        <div class="column is-12 input-group dates" v-if="form.course === 'advanced' && availableDatesAdvanced.length > 0">
                            <label v-for="(date, index) in availableDatesAdvanced" :key="index" class="check-container">
                                <input type="checkbox" :value="date" v-model="form.checkedDates" name="available-dates">
                                <span class="checkmark"></span>
                                {{ dateFormat(date.start) }} <span class="till">{{ $t(`signup.till`) }}</span> {{ dateFormat(date.end) }}
                            </label>
                            <p>
                                {{ $t(`signup.form.nodate1`) }} <router-link :to="{path: '/contact'}" class="inline-link">contact</router-link> {{ $t(`signup.form.nodate2`) }}
                            </p>
                        </div>
                    </div>
                    <div v-else>
                        <h4>{{ $t(`signup.form.noCourse`) }}</h4>
                    </div>

                    <textarea name="comments" :aria-label="$t(`signup.form.comments`)" cols="30" rows="3" :placeholder="$t(`signup.form.comments`)" class="form-field" v-model="form.comment"></textarea>

                    <small class="has-text-danger" v-if="formError">{{ formError }}</small>
                    <button type="submit" class="loup-button" @click="submitForm()" :disabled="sending">{{ sending ? $t(`signup.form.sending`) : $t(`signup.form.send`) }}</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        name: "SignUp",
        data: function () {
            return {
                availableDatesBasic: [],
                availableDatesAdvanced: [],
                form: {
                    name: '',
                    email: '',
                    phone: '',
                    course: '',
                    checkedDates: [],
                    comment: '',
                    lang: '',
                    token: ''
                },
                formSuccess: null,
                formError: '',
                sending: false
            }
        },
        mounted() {
            axios
                .get('https://sheets.googleapis.com/v4/spreadsheets/1cn9V3e_r3XvFot69_tnwHK3lr4LcebM5JURbVOlZhJM/values/A4%3AG61?key=AIzaSyB4DeHLJfdih63OkYAzJWvzSlaahvkV_s8')
                .then(response => {
                    response.data.values.forEach(row => {
                        if(row.length > 0) {
                            if(typeof row[0] !== 'undefined' && row[0].length > 0 && row[2] !== "x" && this.$moment(row[0], 'DD-MM-YYYY').isAfter(this.$moment())) {
                                let basic = {start: row[0], end: row[1]};
                                this.availableDatesBasic.push(basic)
                            }
                            if(typeof row[4] !== 'undefined' && row[4].length > 0 && row[6] !== "x" && this.$moment(row[4], 'DD-MM-YYYY').isAfter(this.$moment())) {
                                let advanced = {start: row[4], end: row[5]};
                                this.availableDatesAdvanced.push(advanced)
                            }
                        }
                    })
                })
        },
        created() {
            if (this.$route.query.course) {
                this.form.course = this.$route.query.course
            }
        },
        methods: {
            dateFormat(date) {
                return this.$moment(date, "DD-MM-YYYY").format('dd DD MMM YYYY');
            },

            emptyDates() {
                this.form.checkedDates = [];
            },

            submitForm() {
                if (!this.form.email && !this.form.phone) {
                    this.formError = this.$t(`contact.form.phoneEmailError`);
                    return;
                }

                this.sending = true
                this.$recaptcha('login').then((token) => {
                    this.form.token = token

                    if (this.form.token.length > 0) {
                        this.form.lang = this.$i18n.locale
                        axios
                            .post('https://loupsolitaire.be/signupmail.php', this.form)
                            .then(response => {
                                this.sending = false
                                if(response.data.success === "true") {
                                    this.formSuccess = true
                                }
                            })
                            .catch(() => {
                                this.formError = this.$t(`error.send`);
                            })
                    } else {
                        this.formError = this.$t(`error.send`);
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .container {
        min-height: 70vh;
        display: flex;
        align-items: center;

        background-image: url("../assets/shapes/background-watermark.svg");
        background-repeat: no-repeat;
        background-size: 75% 100%;
        background-position: right;
    }

    .text-block {
        align-self: center;
    }

    .loup-button {
        margin-top: 1.5rem;
        font-weight: 900;
    }

    .till:before {
        content: "\00a0 ";
    }
    .till:after {
        content: "\00a0 ";
    }

    .inline-link {
        font-weight: bold;
    }
</style>
